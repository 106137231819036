 import request from '@/utils/request'

 // 待办添加
 export function addTodoList(data) {
   return request('post', '/api/todo/addList', data)
 }

 // 待办列表
 export function pageTodoList(data) {
   return request('post', '/api/todo/todoList', data)
 }

 // 待办修改
 export function updateTodoList(data) {
   return request('post', '/api/todo/updateList', data)
 }

 // 待办删除
 export function deleteTodoList(data) {
   return request('post', '/api/todo/deleteList', data)
 }
 