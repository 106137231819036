<template>
  <div id="todolist">
    <!-- 头部 -->
    <header class="header">
      <div class="header-L">
        <!-- 模糊查询 -->
        <el-input
          v-model="kw"
          placeholder="查询（ 标题 / 内容 ）"
          @input="kwChange"
          class="mr20"
        />
        <!-- 状态查询 -->
        <el-select
          v-model="selStatus"
          placeholder="请选择状态"
          @change="selStatusChange"
        >
          <el-option label="全部状态" value=""> </el-option>
          <el-option
            v-for="item in optionsStatus"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="header-R">
        <el-button type="primary" @click="handleAdd">新增待办</el-button>
      </div>
    </header>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      border
      stripe
      style="width: 100%"
      @row-click="rowClick"
    >
      <el-table-column
        prop="todoTitle"
        label="标题"
        width="260"
        align="center"
      />
      <el-table-column prop="todoContent" label="内容" />
      <el-table-column
        prop="createdAt"
        label="发布日期"
        width="120"
        align="center"
        :formatter="formatterDate"
      />
      <el-table-column
        prop="todoDate"
        label="截止日期"
        width="120"
        align="center"
        :formatter="formatterDate"
      />
      <el-table-column
        prop="updatedAt"
        label="更新日期"
        width="120"
        align="center"
        :formatter="formatterDate"
      />
      <el-table-column
        prop="todoGrade"
        label="分组"
        :formatter="changeGrade"
        width="100"
        align="center"
      />
      <el-table-column
        prop="todoType"
        label="类型"
        :formatter="changeType"
        width="100"
        align="center"
      />
      <el-table-column
        prop="todoStatus"
        label="状态"
        :formatter="changeStatus"
        width="100"
        align="center"
      />
      <el-table-column width="200" align="center" label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click.stop="handleEdit(scope.$index, scope.row)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click.stop="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <footer>
      <el-pagination
        background
        :total="total"
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </footer>
    <!-- 弹框 -->
    <el-dialog
      :visible.sync="dialogFormVisible"
      :title="formTitle"
      width="30%"
      custom-class="add-dialog"
    >
      <el-form :model="form" :disabled="formDisabled">
        <!-- 标题 -->
        <el-form-item label="标题" :label-width="formLabelWidth">
          <el-input v-model="form.todoTitle" autocomplete="off"></el-input>
        </el-form-item>
        <!-- 内容 -->
        <el-form-item label="内容" :label-width="formLabelWidth">
          <el-input v-model="form.todoContent" type="textarea"></el-input>
        </el-form-item>
        <!-- 截止日期 -->
        <el-form-item label="截至日期" :label-width="formLabelWidth">
          <el-date-picker
            v-model="form.todoDate"
            type="date"
            placeholder="请选择截至日期"
          >
          </el-date-picker>
        </el-form-item>
        <!-- 分组 -->
        <el-form-item label="分组" :label-width="formLabelWidth">
          <el-select v-model="form.todoGrade" placeholder="请选择">
            <el-option
              v-for="item in optionsGrade"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 类型 -->
        <el-form-item label="类型" :label-width="formLabelWidth">
          <el-select v-model="form.todoType" placeholder="请选择">
            <el-option
              v-for="item in optionsType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 状态 -->
        <el-form-item
          label="状态"
          :label-width="formLabelWidth"
          v-if="formTitle != '新增任务'"
        >
          <el-select v-model="form.todoStatus" placeholder="请选择">
            <el-option
              v-for="item in optionsStatus"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="!formDisabled">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveList">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  addTodoList,
  updateTodoList,
  pageTodoList,
  deleteTodoList,
} from "@/api/home/todolist.js";
import { formatterDate } from "@/utils/utils.js";
export default {
  data() {
    return {
      kw: "", //查询条件
      tableData: [], //表格数据
      page: 1,
      total: null, //总条数
      dialogFormVisible: false, //弹框开关
      formDisabled: false, //表单禁用开关
      selStatus: "",
      form: {
        todoTitle: "", //标题
        todoContent: "", //内容
        todoDate: "", //截止日期
        todoStatus: "", //状态
        todoType: "", //类型
        todoGrade: "", //分组
        todoDelete: "", //显影开关
      },
      optionsStatus: [
        {
          value: 1,
          label: "待办",
        },
        {
          value: 2,
          label: "已完成",
        },
      ],
      optionsType: [
        {
          value: 1,
          label: "购物",
        },
        {
          value: 2,
          label: "事情",
        },
      ],
      optionsGrade: [
        {
          value: 1,
          label: "公共",
        },
        {
          value: 2,
          label: "个人",
        },
      ],
      formTitle: "", //弹框标题
      formLabelWidth: "120px", //输入框宽度
      //状态转换
      changeStatusObj: {
        1: "待办",
        2: "完成",
      },
      //类型转换
      changeTypeObj: {
        1: "购物",
        2: "事情",
      },
      //分组转换
      changeGradeObj: {
        1: "公共",
        2: "个人",
      },
    };
  },
  mounted() {
    this.pageTodoList();
  },
  methods: {
    // 【转换】日期
    formatterDate(row, column, cellValue) {
      return formatterDate(cellValue, "yyyy-MM-dd");
    },
    // 【转换】状态
    changeStatus(row, column, cellValue) {
      return this.changeStatusObj[cellValue];
    },
    // 【转换】类型
    changeType(row, column, cellValue) {
      return this.changeTypeObj[cellValue];
    },
    // 【转换】分组
    changeGrade(row, column, cellValue) {
      return this.changeGradeObj[cellValue];
    },

    // 【请求】分页任务列表
    pageTodoList() {
      let data = {
        page: this.page,
        todoStatus: this.selStatus,
        kw: this.kw,
      };
      pageTodoList(data).then((res) => {
        this.tableData = res.data.data;
        this.total = res.data.total;
      });
    },

    // 【监听】分页点击事件
    handleCurrentChange(val) {
      this.page = val;
      this.pageTodoList();
    },

    // 【监听】模糊查询
    kwChange: _.debounce(function () {
      this.pageTodoList();
    }, 500),

    // 【监听】状态筛选
    selStatusChange() {
      this.pageTodoList();
    },

    // 【监听】新增按钮
    handleAdd() {
      this.formTitle = "新增任务";
      this.dialogFormVisible = true;
      this.formDisabled = false;
      this.form = {
        todoTitle: "",
        todoContent: "",
        todoDate: "",
        todoStatus: 1,
        todoType: 1,
        todoGrade: 1,
        todoDelete: 1,
      };
    },

    // 【监听】行点击事件
    rowClick(row, column, event) {
      this.formTitle = "查看任务";
      this.dialogFormVisible = true;
      this.formDisabled = true;
      this.form = row;
    },

    // 【监听】编辑按钮
    handleEdit(index, row) {
      this.formTitle = "编辑任务";
      this.dialogFormVisible = true;
      this.formDisabled = false;
      this.form = row;
    },

    // 【监听】弹框表单提交
    saveList() {
      let data = this.form;
      // 编辑
      if (this.form.todoId) {
        updateTodoList(data).then((res) => {
          if (res.success) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.dialogFormVisible = false;
            this.pageTodoList();
          }
        });
      }
      // 新增
      else {
        const { todoTitle, todoDate, todoContent } = this.form;
        if (!todoTitle) {
          return this.$message.error("标题不能为空");
        }
        if (!todoDate) {
          return this.$message.error("请选择截止日期");
        }
        if (!todoContent) {
          return this.$message.error("内容不能为空");
        }
        addTodoList(data).then((res) => {
          if (res.success) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.dialogFormVisible = false;
            this.pageTodoList();
          }
        });
      }
    },

    // 【监听删除】
    handleDelete(index, row) {
      let _this = this;
      this.$confirm("确定要删除该条任务吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log('row.id: ', row.id);
          let data = {
            todoId: row.todoId,
          };
          deleteTodoList(data).then((res) => {
            if (res.success) {
              _this.$message({
                message: res.msg,
                type: "success",
              });
              _this.dialogFormVisible = false;
              _this.pageTodoList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#todolist {
  padding: 24px;
  box-sizing: border-box;
}

.header {
  height: 0.6rem;
  padding: 0.1rem 0;
  display: flex;
  justify-content: space-between;
}

.header-L {
  display: flex;
}

footer {
  margin: 0.1rem 0;
}
</style>
